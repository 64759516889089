const client_keys = {
	spotifyClientId: "d14c880b762d4dd09582c730020cbf36",
	amazonClientId: "amzn1.application-oa2-client.d987fe1cfee64428b87edc5e8c73cd3d",
	googleClientId: "147980774924-j5rbnvb4l9qp1mfj7mhoueo0u80kn6ku.apps.googleusercontent.com",
	facebookClientId: "1349837992023813"
}

const GOOGLE_API_KEY = "AIzaSyCs6Koz_JP8wPoCck4S2yD-SmxrgmMvJhY";

export const environment = {
	production: true,
	baseUrl: 'https://api.yokdigital.com/',
	smartLinksUrl: 'https://yoklink.it/',
	spotifyAuthUrl: '',
	iqmUrl: 'https://network.yokdigital.com/api/v3/',
	facebookApiUrl: "https://graph.facebook.com/v14.0/",
	iqmBasicToken: "eWlYaWVxdXVHNEVpd2FoOG9ocnU6emVpdGhfYWVzYTVBaVBp",
	amazonAuthUrl:'',
	googleAuthUrl: "https://accounts.google.com/o/oauth2/v2/auth?scope=https://www.googleapis.com/auth/adwords&access_type=offline&response_type=code&client_id=" + client_keys.googleClientId + "&prompt=consent&redirect_uri=" + window.location.origin + "/google-auth-callback",
	facebookAuthUrl: "https://www.facebook.com/v14.0/dialog/oauth?client_id=" + client_keys.facebookClientId + "&redirect_uri=" + window.location.origin + "/facebook-auth-callback&response_type=token&scope=ads_management,pages_manage_ads,ads_read,pages_read_engagement,pages_manage_cta,pages_show_list,instagram_basic",
	GOOGLE_MAPS_ID:"18da0333d37f7b3f",
	AWS_ACCESS_KEY_ID: 'AKIAYZ7N6XTMUFZ644E2',
	AWS_SECRET_ACCESS_KEY: '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',
	AWS_S3_BUCKET_REGION: 'us-east-1',
	AWS_S3_BUCKET_NAME: "yok-ads-creative",
  	STRIPE_PUBLISHABLE_KEY: "pk_live_51HxD0PDzfMRlLJfVQY39BrF2esOzdrkFQDbys4kiz5PIsdEzONGu4z6SKKir8qn8pLnqxJLEeGUqYRV3utuO6tl5002JjvRq7m"
};